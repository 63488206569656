import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//styling & animation
import styled from "styled-components";
import { motion } from "framer-motion";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  addLetter,
  addTitle,
  addSolution,
  addNewWord,
  clearSelectedFile,
  deletUploadedFile,
} from "../actions/uploadAction";

//components
import DropZone from "../components/DropZone";

const LautehoerenWoerter = () => {
  //init history
  const history = useHistory();
  //init dispatch
  const dispatch = useDispatch();
  //from state
  const { picture } = useSelector((state) => state.update);
  //local state
  const [letter, setLetter] = useState("");
  const [name, setName] = useState("");
  const [solutionLeft, setSolutionLeft] = useState("");
  const [solutionMid, setSolutionMid] = useState("");
  const [solutionRight, setSolutionRight] = useState("");

  const chooseLetterHandler = (e) => {
    setLetter(e.target.value.toLowerCase());
  };

  const chooseNameHandler = (e) => {
    setName(e.target.value);
  };

  const solutionLeftHandler = (e) => {
    setSolutionLeft(e.target.checked);
  };
  const solutionMidHandler = (e) => {
    setSolutionMid(e.target.checked);
  };
  const solutionRightHandler = (e) => {
    setSolutionRight(e.target.checked);
  };

  const submitWordHandler = () => {
    const solutionArray = [];
    solutionArray[0] = Number(solutionLeft);
    solutionArray[1] = Number(solutionMid);
    solutionArray[2] = Number(solutionRight);

    dispatch(addSolution(solutionArray));
    dispatch(addLetter(letter));
    dispatch(addTitle(name));
    dispatch(addNewWord(name, letter, picture.url, solutionArray));
    //reset solution
    setSolutionLeft("");
    setSolutionMid("");
    setSolutionRight("");
    //reset image info
    setLetter("");
    setName("");
  };

  const deleteWordHandler = () => {
    //delete picture in cloud storage
    dispatch(deletUploadedFile(picture.name));
    //reset image info
    setLetter("");
    setName("");
    //reset solution
    setSolutionLeft("");
    setSolutionMid("");
    setSolutionRight("");
    //reload page
    history.push("/lautehoerenwoerter");
  };

  return (
    <Wrapper>
      <h2>Neues Wort erstellen</h2>
      <DropZone />
      {picture !== null ? (
        <>
          <ChooseName>
            <p>Wie heißt dieses Wort?</p>
            <input type='text' onChange={chooseNameHandler} value={name} />
          </ChooseName>

          <ChooseLetter>
            <p>Welcher Laut soll erkannt werden?</p>
            <p>Den Laut nur einmal eingeben</p>
            <input
              type='text'
              onChange={chooseLetterHandler}
              value={letter}
              maxLength='3'
            />
          </ChooseLetter>

          <ChooseSolution>
            <p>Lösung eingeben</p>
            <div id='Checkboxes'>
              <div className='checkboxDiv'>
                <input
                  type='checkbox'
                  onChange={solutionLeftHandler}
                  name='left'
                />
                <label htmlFor='left'>Wortanfang</label>
              </div>
              <div className='checkboxDiv'>
                <input
                  type='checkbox'
                  onChange={solutionMidHandler}
                  name='mid'
                />
                <label htmlFor='mid'>Wortmitte</label>
              </div>
              <div className='checkboxDiv'>
                <input
                  type='checkbox'
                  onChange={solutionRightHandler}
                  name='right'
                />
                <label htmlFor='right'>Wortende</label>
              </div>
            </div>
          </ChooseSolution>

          <SubmitWord onClick={submitWordHandler}>
            <p>Wort hinzufügen</p>
          </SubmitWord>
          <DeleteWord onClick={deleteWordHandler}>
            <p>Abbrechen und Bild löschen</p>
          </DeleteWord>
        </>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-bottom: 1rem;
  }
`;

const ChooseLetter = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  input {
    width: 100%;
    font-size: 2rem;
    text-align: center;
  }
  p {
    padding: 0;
  }
`;

const ChooseName = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  input {
    width: 100%;
    font-size: 2rem;
    text-align: center;
  }
  p {
    padding: 0;
  }
`;

const ChooseSolution = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  input[type="checkbox"] {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 1rem;
  }
  label {
    margin-left: 0.5rem;
  }
  #Checkboxes {
    display: flex;
  }
  .checkboxDiv {
    display: flex;
    justify-content: center;
  }
`;

const SubmitWord = styled(motion.div)`
  cursor: pointer;
  width: fit-content;
  background: rgb(50, 50, 50);
  margin: 1rem;
  p {
    color: white;
  }
`;

const DeleteWord = styled(motion.div)`
  cursor: pointer;
  width: fit-content;
  background: rgb(253, 51, 51);
  margin: 1rem;
  p {
    color: white;
  }
`;

export default LautehoerenWoerter;
