import axios from "axios";
import { getAllWordsURL, deleteImageURL } from "../api";

export const addNewWord = (title, letter, url, solution) => async (
  dispatch
) => {
  const newWordJSON = {
    title: title,
    letterType: letter,
    wordPicture: url,
    solution: solution,
  };
  const res = await axios.post(getAllWordsURL(), newWordJSON);
  console.log("res:", res);

  dispatch({
    type: "ADD_NEW_WORD",
  });
};

export const selectedFile = (file) => async (dispatch) => {
  dispatch({
    type: "SELECTED_FILE",
    payload: {
      picture: file,
    },
  });
};

export const inDropZone = (drop) => async (dispatch) => {
  dispatch({
    type: "IN_DROP_ZONE",
    payload: {
      inDropZone: drop,
    },
  });
};

export const uploadingTrue = () => async (dispatch) => {
  dispatch({
    type: "UPLOADING_TRUE",
  });
};

export const uploadingFalse = () => async (dispatch) => {
  dispatch({
    type: "UPLOADING_FALSE",
  });
};

export const addSolution = (solution) => async (dispatch) => {
  dispatch({
    type: "ADD_SOLUTION",
    payload: {
      solution: solution,
    },
  });
};

export const addTitle = (title) => async (dispatch) => {
  dispatch({
    type: "ADD_TITLE",
    payload: {
      title: title,
    },
  });
};

export const addLetter = (letter) => async (dispatch) => {
  dispatch({
    type: "ADD_LETTER",
    payload: {
      letter: letter,
    },
  });
};

export const clearSelectedFile = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_SELECTED_FILE",
  });
};

export const deletUploadedFile = (filename) => async (dispatch) => {
  const urlWithFilename = deleteImageURL() + "?filename=" + filename;
  const res = await axios.delete(urlWithFilename);
  console.log("res:", res);

  dispatch({
    type: "DELETE_IMAGE",
  });
};
