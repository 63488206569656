import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { loginUserURL } from "../api";

//styling & animation
import styled from "styled-components";
import { motion } from "framer-motion";

//components
import { CircleLoader } from "../components/CircleLoader";

const Admin = () => {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [data, setData] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);

  const enterHandler = (e) => {
    if (e.keyCode === 13) {
      loginHandler();
    }
  };

  const loginHandler = () => {
    setLoadingUser(true);
    axios({
      method: "POST",
      data: {
        username: loginUsername,
        password: loginPassword,
      },
      withCredentials: true,
      //url: "http://localhost:3000/user/login",
      url: loginUserURL(),
    })
      .then((res) => {
        console.log(res);
        if (res.data !== "user does not exist") {
          setData(res.data);
        }
        setLoadingUser(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Wrapper>
      <LoginForm>
        <h2>Login</h2>
        <input
          type='text'
          placeholder='username'
          onChange={(e) => setLoginUsername(e.target.value)}
          value={loginUsername}
          autofocus='autofocus'
        />
        <input
          type='password'
          placeholder='password'
          onChange={(e) => setLoginPassword(e.target.value)}
          value={loginPassword}
          onKeyDown={enterHandler}
        />
        <button onClick={loginHandler}>login</button>
      </LoginForm>
      {loadingUser ? <CircleLoader /> : null}
      <Adminoptions>
        {data ? (
          <>
            <h2>Hi {data.username}</h2>
            <Link to='/lautehoerenwoerter' className='linkItem'>
              Wort zu "Laute hören" hinzufügen
            </Link>
          </>
        ) : null}
      </Adminoptions>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  h2 {
    margin: 1rem;
  }
  input {
    margin: 0.4rem;
    font-size: 1.5rem;
  }
  button {
    margin: 0.4rem;
    font-size: 1.5rem;
    padding: 0.2rem 2rem;
    border-radius: 1rem;
    cursor: pointer;
  }
`;

const Adminoptions = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin: 2rem;
  }
  .linkItem {
    background: rgb(170, 170, 170);
    border-radius: 0.5rem;
    padding: 1rem;
    color: white;
  }
`;
export default Admin;
