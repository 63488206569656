import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const Nav = () => {
  //current location for current animation
  const { pathname } = useLocation();

  return (
    <SyledNav>
      <Link className='logo' to='/'>
        <h1>Julia Löffler</h1>
      </Link>
      <ul>
        <li>
          <Line
            transition={{ duration: 0.75, ease: "easeOut" }}
            initial={{ width: "0%" }}
            animate={{
              width: pathname === "/" ? "100%" : "0%",
              opacity: pathname === "/" ? "1" : "0",
            }}
          />
          <Link to='/'>Startseite</Link>
        </li>
        <li>
          <Line
            transition={{ duration: 0.75, ease: "easeOut" }}
            initial={{ width: "0%" }}
            animate={{
              width:
                pathname === "/lautehoerenueben" || pathname === "/lautehoeren"
                  ? "100%"
                  : "0%",
              opacity:
                pathname === "/lautehoerenueben" || pathname === "/lautehoeren"
                  ? "1"
                  : "0",
            }}
          />
          <Link to='/lautehoeren'>Laute hören</Link>
        </li>
      </ul>
    </SyledNav>
  );
};

const SyledNav = styled(motion.nav)`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  min-height: 5vh;
  background: rgba(255, 255, 255, 0.8);
  @media (max-width: 880px) {
    justify-content: center;
  }
  @media (max-width: 550px) {
    flex-direction: column;
  }
  ul {
    display: flex;
    list-style-type: none;
    margin-left: 1rem;
    li {
      margin: 1.5rem 1rem;
      position: relative;

      a {
        color: rgb(131, 131, 131);
        font-size: 1rem;
      }
    }
  }
  .logo {
    margin-left: 2rem;
    cursor: pointer;
  }
  h1 {
    font-family: "Zeyada", cursive;
    font-size: 2rem;
  }
`;

const Line = styled(motion.div)`
  height: 0.2rem;
  width: 0%;
  background: rgb(55, 55, 55);
  position: absolute;
  top: -0.3rem;
`;

export default Nav;
