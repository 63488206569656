import React, { useState } from "react";
import axios from "axios";
import { uploadImageURL } from "../api";

//styling & animation
import styled from "styled-components";
import { motion } from "framer-motion";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  selectedFile,
  inDropZone,
  uploadingTrue,
  uploadingFalse,
} from "../actions/uploadAction";

//components
import { CircleLoader } from "./CircleLoader";

const DropZone = () => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadedFile, setUploadedFile] = useState({});

  //init dispatch
  const dispatch = useDispatch();
  //from state
  const { picture, stillUploading } = useSelector((state) => state.update);

  const dragOverHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(inDropZone(true));
  };

  const dragEnterHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(inDropZone(true));
  };

  const dropHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setFile(event.dataTransfer.files[0]);
    setFileName(event.dataTransfer.files[0].name);

    if (file) {
      dispatch(inDropZone(false));
    }
  };

  const uploadPicture = async () => {
    const formData = new FormData();
    formData.append("file", file);

    dispatch(uploadingTrue());

    //axios
    try {
      console.log(uploadImageURL());
      const res = await axios.post(uploadImageURL(), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      //answer from server
      const { name, url } = res.data;
      const fileObj = {
        name,
        url,
      };
      setUploadedFile(fileObj);
      dispatch(selectedFile(fileObj));
      //reset
      setFile("");
      setFileName("");
      dispatch(inDropZone(false));
      dispatch(uploadingFalse());
    } catch (err) {
      console.log("err:", err);
    }
  };

  return (
    <Wrapper>
      {picture === null ? (
        <>
          <p>Bild hinzufügen vom PC</p>
          <DropDiv
            onDrop={(event) => dropHandler(event)}
            onDragOver={(event) => dragOverHandler(event)}
            onDragEnter={(event) => dragEnterHandler(event)}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <p>drop picture here</p>
          </DropDiv>
          <p>{fileName}</p>
          {fileName !== "" ? (
            <UploadPicture
              onClick={uploadPicture}
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              <p>upload file</p>
            </UploadPicture>
          ) : null}
        </>
      ) : null}
      {stillUploading ? <CircleLoader /> : null}
      {picture !== null ? (
        <PicturePreview>
          <h3>Hochgeladenes Bild</h3>
          <p>{uploadedFile.name}</p>
          <img src={uploadedFile.url} alt='preview' />
        </PicturePreview>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DropDiv = styled(motion.div)`
  height: 10vh;
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(200, 200, 200);

  border: none;
  border-radius: 1rem;
  p {
    color: white;
  }
`;

const UploadPicture = styled(motion.div)`
  cursor: pointer;
  width: fit-content;
  background: rgb(50, 50, 50);
  p {
    color: white;
  }
`;

const PicturePreview = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0rem;
  img {
    height: 25vh;
  }
`;

export default DropZone;
