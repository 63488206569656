import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  
  &::-webkit-scrollbar{
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb{
    background: darkgrey;
  }
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
  }

 

}
body{
  font-family: 'Noto Sans', sans-serif;

  width: 100%;
  
}
h2{
  font-size: 2rem;
  color: rgb(170,170,170);
}
h3{
  font-size: 1.2rem;
  color: rgb(70,70,70);
  padding: 1.5rem 0;
}
p{
  font-size: 1rem;
  color: rgb(100,100,100);
  padding: 0.5rem;
  line-height: 200%
}
a{
  text-decoration: none;
  color: rgb(100,100,100);
}
img{
  display: block;
}

`;

export default GlobalStyle;
