export const answerShape = {
  visible: {
    opacity: 1,
    scale: 1.1,
    borderRadius: "0%",
    transition: {
      duration: 1,
    },
  },
  hidden: {
    opacity: 0.5,
    scale: 0.8,
    borderRadius: "50%",
    transition: {
      duration: 1,
    },
  },
};

export const answerColor = {
  true: {
    backgroundColor: "#33c57c",
    transition: {
      duration: 1.5,
    },
  },
  false: {
    backgroundColor: "#ff7373",
    transition: {
      duration: 1.5,
    },
  },
};

export const answerOpacity = {
  true: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  false: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};
