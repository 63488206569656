import { combineReducers } from "redux";
import wordsReducer from "./wordsReducer";
import uploadReducer from "./uploadReducer";

const rootReducer = combineReducers({
  words: wordsReducer,
  update: uploadReducer,
});

export default rootReducer;
