import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//styling & animation
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { answerShape, answerColor, answerOpacity } from "../animations";
import ok from "../img/ok-mark.svg";
import notok from "../img/wrong.svg";
import arrowRight from "../img/right-arrow.svg";
import shuffle from "../img/shuffle.svg";

//redux logic
import { useSelector } from "react-redux";

const Uebungsaufgabe = () => {
  //get word state & destruct
  const { words } = useSelector((state) => state.words);

  //local states
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answer0, setAnswer0] = useState(false);
  const [answer1, setAnswer1] = useState(false);
  const [answer2, setAnswer2] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [showCorrctOrNot, setShowCorrctOrNot] = useState(false);

  const showNextWord = () => {
    if (currentIndex < words.length - 1 && words.length > 0) {
      console.log("currentIndex:", currentIndex);
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
    setAnswer0(false);
    setAnswer1(false);
    setAnswer2(false);
    setShowCorrctOrNot(false);
  };

  const showNextRandomWord = () => {
    const arrayIndex = Math.floor(Math.random() * words.length);
    setCurrentIndex(arrayIndex);
    setAnswer0(false);
    setAnswer1(false);
    setAnswer2(false);
    setShowCorrctOrNot(false);
  };

  const letterHandler = (str) => {
    let arrStr = str.split("");
    arrStr[0] = arrStr[0].toUpperCase();
    arrStr.push(" ");
    const newStr = arrStr.join("");
    return newStr;
  };

  const checkAnswerHandler = () => {
    if (
      answer0 === Boolean(words[currentIndex].solution[0]) &&
      answer1 === Boolean(words[currentIndex].solution[1]) &&
      answer2 === Boolean(words[currentIndex].solution[2])
    ) {
      setAnswer(true);
      setShowCorrctOrNot(true);
    } else {
      setAnswer(false);
      setShowCorrctOrNot(true);
    }
  };

  //init history
  const history = useHistory();
  const backHandler = () => {
    history.push("/lautehoeren");
  };

  return (
    <Wrapper>
      {words !== null ? (
        <>
          <h1>
            {letterHandler(words[currentIndex].letterType)}
            {words[currentIndex].letterType}
          </h1>
          <img
            src={words[currentIndex].wordPicture}
            alt='wordpicture'
            id='wordpicture'
          />

          <Answer>
            <AnswerBox
              onClick={() => {
                setAnswer0(!answer0);
                setShowCorrctOrNot(false);
              }}
              variants={answerShape}
              whileHover={{ scale: 1.1 }}
              animate={answer0 ? "show" : "hidden"}
            ></AnswerBox>
            <AnswerBox
              onClick={() => {
                setAnswer1(!answer1);
                setShowCorrctOrNot(false);
              }}
              variants={answerShape}
              whileHover={{ scale: 1.1 }}
              animate={answer1 ? "show" : "hidden"}
            ></AnswerBox>
            <AnswerBox
              onClick={() => {
                setAnswer2(!answer2);
                setShowCorrctOrNot(false);
              }}
              variants={answerShape}
              whileHover={{ scale: 1.1 }}
              animate={answer2 ? "show" : "hidden"}
            ></AnswerBox>
          </Answer>
          <CheckAnswer whileHover={{ scale: 1.2 }} onClick={checkAnswerHandler}>
            <p>Richtig?</p>
          </CheckAnswer>
          <AnimatePresence>
            {showCorrctOrNot ? (
              <motion.div
                key='modal'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
              >
                <CorrectOrNot
                  variants={answerColor}
                  animate={answer ? "true" : "false"}
                >
                  <motion.img
                    src={ok}
                    alt='ok'
                    variants={answerOpacity}
                    animate={answer ? "true" : "false"}
                  />
                  <motion.img
                    key='modal'
                    src={notok}
                    alt='notok'
                    variants={answerOpacity}
                    animate={answer ? "false" : "true"}
                  />
                </CorrectOrNot>
              </motion.div>
            ) : null}
          </AnimatePresence>
          <NextStep>
            <Next onClick={showNextRandomWord}>
              <p>Nächstes zufälliges Wort</p>{" "}
              <img src={shuffle} alt='rightarrow' />
            </Next>
            <Next onClick={showNextWord}>
              <p>Nächstes Wort</p> <img src={arrowRight} alt='rightarrow' />
            </Next>
          </NextStep>
        </>
      ) : (
        <Back onClick={backHandler}>
          <h2>Zuerst Buchstaben wählen</h2>
        </Back>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  padding: 1rem 2rem;
  border: none;
  border-radius: 2rem;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  h1 {
    font-size: 5rem;
    // letter-spacing: 1rem;
  }
  #wordpicture {
    height: 25vh;
  }
`;

const Answer = styled(motion.div)`
  display: flex;
`;
const AnswerBox = styled(motion.div)`
  width: 4rem;
  height: 4rem;
  background: #3d6b7a;
  border: none;
  margin: 1rem;
  cursor: pointer;
`;

const CheckAnswer = styled(motion.div)`
  width: fit-content;
  height: 3rem;
  background: lightblue;
  cursor: pointer;
  margin: 1rem 0;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 1rem;
  p {
    text-align: center;
    color: black;
    font-size: 1.5rem;
  }
`;

const CorrectOrNot = styled(motion.div)`
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin: 1rem;
  position: relative;

  img {
    height: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
  }
`;

const Back = styled(motion.div)`
  width: fit-content;
  background: #d33f3f;
  padding: 1.5rem;
  border-radius: 1rem;
  margin: 2rem;
  cursor: pointer;
  h2 {
    color: white;
  }
`;

const NextStep = styled(motion.div)`
  display: flex;
`;

const Next = styled(motion.div)`
  cursor: pointer;
  width: fit-content;
  background: #3d6b7a;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  p {
    color: white;
    font-size: 1.25rem;
  }
  img {
    height: 2rem;
  }
`;

export default Uebungsaufgabe;
