//base url
const base_url = "https://lauteapi-gkk4grmmhq-ew.a.run.app/";

//words url
const words_url = `words`;
export const getAllWordsURL = () => `${base_url}${words_url}`;

//upload image url
const image_url = `image`;
const upload_url = `/upload`;
export const uploadImageURL = () => `${base_url}${image_url}${upload_url}`;

//delete image url
export const deleteImageURL = () => `${base_url}${image_url}`;

//user login url
const user_url = `user`;
const login_url = `/login`;
export const loginUserURL = () => `${base_url}${user_url}${login_url}`;

//letterArray url
const letter_url = `letter`;
export const letterArrayURL = () => `${base_url}${letter_url}`;
