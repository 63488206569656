import axios from "axios";
//API url
import { getAllWordsURL, letterArrayURL } from "../api";

export const loadAllWords = () => async (dispatch) => {
  //set isloading
  dispatch({
    type: "LOADING_TRUE",
  });

  //fetch with axios
  const wordsData = await axios.get(getAllWordsURL());
  dispatch({
    type: "GET_ALL_WORDS",
    payload: {
      words: wordsData.data,
    },
  });
};

export const loadSpecificWords = (specific_letter) => async (dispatch) => {
  //set isloading
  dispatch({
    type: "LOADING_TRUE",
  });

  //fetch with axios
  const wordsData = await axios.get(`${getAllWordsURL()}/${specific_letter}`);
  dispatch({
    type: "GET_SPECIFIC_WORDS",
    payload: {
      words: wordsData.data,
    },
  });
};

export const loadLetterArray = () => async (dispatch) => {
  //fetch with axios
  const letterArray = await axios.get(letterArrayURL());
  dispatch({
    type: "GET_ALL_LETTERS",
    payload: {
      letters: letterArray.data,
    },
  });
};
