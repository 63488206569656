const initialState = {
  picture: null,
  inDropZone: false,
  stillUploading: false,
  solution: [],
  title: "",
  letter: "",
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SELECTED_FILE":
      return {
        ...state,
        picture: action.payload.picture,
      };

    case "UPLOADING_TRUE":
      return {
        ...state,
        stillUploading: true,
      };

    case "UPLOADING_FALSE":
      return {
        ...state,
        stillUploading: false,
      };

    case "IN_DROP_ZONE":
      return {
        ...state,
        inDropZone: action.payload.inDropZone,
      };

    case "ADD_SOLUTION":
      return {
        ...state,
        solution: action.payload.solution,
      };
    case "CLEAR_SELECTED_FILE":
      return {
        ...state,
        picture: null,
      };

    case "DELETE_IMAGE":
      return {
        ...state,
        picture: null,
        inDropZone: false,
        stillUploading: false,
        solution: [],
        title: "",
        letter: "",
      };

    case "ADD_TITLE":
      return {
        ...state,
        title: action.payload.title,
      };
    case "ADD_LETTER":
      return {
        ...state,
        letter: action.payload.letter,
      };

    case "ADD_NEW_WORD":
      return {
        ...state,
        picture: null,
        solution: [],
        title: "",
        letter: "",
      };
    default:
      return { ...state };
  }
};

export default uploadReducer;
