import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const ContainerStyle = styled(motion.div)`
  position: "absolute";
  top: 0;
  left: 0;
  width: 100vw;
  height: 95vh;
  background: rgba(255, 255, 255, 1);
  z-index: 20;
  display: flex;
  justify-content: center;
`;

const circleStyle = {
  display: "block",
  width: "8rem",
  height: "8rem",
  border: "0.5rem solid #e9e9e9",
  borderTop: "0.5rem solid #3498db",
  borderRadius: "50%",
  boxSizing: "border-box",
  position: "relative",
  top: "20%",
};

const spinTransition = {
  loop: Infinity,
  ease: "linear",
  duration: 1,
};

export const CircleLoader = () => {
  return (
    <ContainerStyle>
      <motion.span
        style={circleStyle}
        animate={{ rotate: 360 }}
        transition={spinTransition}
      />
    </ContainerStyle>
  );
};
