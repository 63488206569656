import React from "react";
import { useHistory } from "react-router-dom";

//styling & animation
import styled from "styled-components";
import { motion } from "framer-motion";

//redux logic
import { useDispatch } from "react-redux";
import { loadSpecificWords } from "../actions/wordsAction";

const LetterCard = ({ letter }) => {
  //init dispatch
  const dispatch = useDispatch();
  //init history
  const history = useHistory();
  const navigateTo = () => {
    if (letter.letter === "all") {
      history.push("/lautehoerenueben");
    } else {
      dispatch(loadSpecificWords(letter.letter));
      history.push("/lautehoerenueben");
    }
  };

  return (
    <Card onClick={navigateTo}>
      <h3>{`${letter.title}`}</h3>
    </Card>
  );
};

const Card = styled(motion.div)`
  cursor: pointer;

  width: fit-content;
  background: #60c2bd;
  border: none;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin: 1rem 1rem 1rem 0;
  h3 {
    color: white;
    font-size: 2rem;
    margin: 0 2rem;
    white-space: nowrap;
    display: block;
    letter-spacing: 0.5rem;
  }
`;

export default LetterCard;
