const initialState = {
  words: null,
  isLoading: true,
  wordArray: null,
};

const wordsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_WORDS":
      return {
        ...state,
        words: action.payload.words,
        isLoading: false,
      };
    case "GET_SPECIFIC_WORDS":
      return {
        ...state,
        words: action.payload.words,
        isLoading: false,
      };
    case "GET_ALL_LETTERS":
      return {
        ...state,
        wordArray: action.payload.letters,
      };
    case "LOADING_TRUE":
      return { ...state, isLoading: true };
    default:
      return { ...state };
  }
};

export default wordsReducer;
