import React, { useEffect } from "react";
//import { wordArray } from "../api";

//components
import LetterCard from "../components/LetterCard";

//styling & animation
import styled from "styled-components";
import { motion } from "framer-motion";

//redux logic
import { useDispatch, useSelector } from "react-redux";
import { loadAllWords } from "../actions/wordsAction";

const Lautehoeren = () => {
  //from state
  const { wordArray } = useSelector((state) => state.words);

  //init dispatch
  const dispatch = useDispatch();
  //fetch words & fill state
  useEffect(() => {
    dispatch(loadAllWords());
  }, [dispatch]);

  return (
    <Container>
      <h1>Laute hören</h1>
      <h2>Welchen Buchstaben willst du üben?</h2>
      <Auswahl>
        {wordArray.map((el) => (
          <LetterCard letter={el} key={el.title} />
        ))}
      </Auswahl>
    </Container>
  );
};

const Container = styled(motion.div)`
  h1 {
    margin: 2rem 0 1rem 2rem;
  }
  h2 {
    margin: 1rem 0 1rem 2rem;
  }
`;

const Auswahl = styled(motion.div)`
  margin: 2rem;
  display: flex;
  flex-wrap: wrap;
`;

export default Lautehoeren;
