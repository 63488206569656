import React from "react";

//components
import Uebungsaufgabe from "../components/Uebungsaufgabe";
import { CircleLoader } from "../components/CircleLoader";

//styling & animation
import styled from "styled-components";
import { motion } from "framer-motion";

//redux logic
import { useSelector } from "react-redux";

const LautehoerenUeben = () => {
  //get word state & destruct
  const { isLoading } = useSelector((state) => state.words);
  return (
    <UebenWrapper>
      <h2>{`Wo hörst du den Laut?`}</h2>
      {isLoading ? <CircleLoader /> : <Uebungsaufgabe />}
    </UebenWrapper>
  );
};

const UebenWrapper = styled(motion.div)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin-top: 0rem;
`;

export default LautehoerenUeben;
